import React, { useRef } from "react";
import { HomeStyle } from "./style.js";
import Hero from "../../components/Hero/Hero";
import Navbar from "../../components/NavBar/Navbar";
import Footer from "../../components/Footer/Footer";
import Tournament from "./Components/Tournament";
import TTable from "./Components/TTable";
import Leaderboard from "./Components/Leaderboard";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import Mode from "../../components/HomeMode/mode";
import IconMessage from "../../components/IconMessage/iconMessage";
import Tab from "../../components/HomeTab/homeTab";

const Home = () => {
  return (
    <>
      <HomeStyle>
        <Navbar message="jh" />
        <Hero />
        <div className="striped-background">
          <div className="fade">
            <div className="fade">
              <div className="fade">
                <div className="gTitle">CREATE TOURNAMENT</div>
                <div className="gSub">Select a game to start a tournament</div>
                <div style={{ height: "20px" }}></div>
                <Link to="/tournament/new">
                  <Button>New Tournament</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <Tournament />
        </div> */}
        {/* <Mode /> */}
        {/* <IconMessage /> */}
        {/* <Tab /> */}
        {/* <Mode /> */}
        {/* <div>
          <TTable />
        </div> */}
        <div>
          <Leaderboard />
        </div>
      </HomeStyle>
      <Footer />
    </>
  );
};

export default Home;
