import { Div } from "./style";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

type IProp = {
    data: any;
  };
  

const InnerButton = (props: IProp) => {
  const navigate = useNavigate();

  console.log('props.data', props.data);

  return (
    <Div onClick={() =>  navigate(`/tournament/${props.data.tournamentid}`)} background={props.data.icon || 'https://ik.imagekit.io/dmkczkqll/Gamelyd/Tournaments/hacker.png?updatedAt=1695897392790'}>
        <div className="name">{props.data.tournamentname}</div>
    </Div>
  );
};

export default InnerButton;
