import React, { useState } from "react";
import { Div } from "./style";
import Tag from "../Tag";
import Button from "../Button";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";

type IProp = {
  //   message: string
  //   time : string
  //   background: string;
  //   data: any;
};

const EmptyTournamentDisplay = (): any => {
  return (
    <Div
      background={
        "https://ik.imagekit.io/dmkczkqll/Gamelyd/Tournaments/warzone2.jpeg?updatedAt=1695930036202"
      }
    >
      <div className="top">
        <div className="paid">
          <div className="feeBox"></div>
        </div>
        <div className="plat" style={{marginTop: '100px'}}>
          <div className="platform"></div>
          <div className="platform">Contact us to sponsor tournament</div>
          <div className="platform"></div>
        </div>
      </div>
      <div className="bottom">
        <div className="infoBox"></div>
        <NavLink to={`/contact-us`} className="">
          <Button>Contact Us</Button>
        </NavLink>
      </div>
    </Div>
  );
};

export default EmptyTournamentDisplay;
