import { Div } from "./style";
import { useState } from "react";
import { motion } from "framer-motion";

type Iprop = {
  // type: string;
  // value: any;
  // onChange: any;
  // label: string;
  item?: any;
};

const TeamCard = ({ item }: Iprop) => {
  const [show, setShow] = useState<boolean>(false);
  // icon

  return (
    <Div onClick={() => setShow(!show)} bg={item.icon ? item.icon : item.Icon}>
      <div className="box">
        {!show && (
          <div className="names">
            <div className="name">
              <div className="teamName">
                {item.teamname ? item.teamname : item.TeamName}
              </div>
            </div>
          </div>
        )}
        {show && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
            className="names"
          >
            <div className="name">
              <div className="teamName">
                {item.teamname ? item.teamname : item.TeamName}
              </div>
            </div>

            <div className="PlayerNames">
              {item.players &&
                item.players.map((data: any, i: number) => (
                  <div className="sin" key={i}>
                    {data.username}
                  </div>
                ))}

              {item.Players &&
                item.Players.map((data: any, i: number) => (
                  <div className="sin" key={i}>
                    {data.username ? data.username : data.UserName}
                  </div>
                ))}
            </div>
          </motion.div>
        )}
      </div>
    </Div>
  );
};
export default TeamCard;
