import { Phone } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { Style } from "./style";
import Loader from "../Loader";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { User } from "../../User";
import Button from "../Button";
import { useApi } from "../../api";

export default function EditUser() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [twitter, setTwitter] = useState("");
  const [insta, setInsta] = useState("");
  const [facebook, setFacebook] = useState("");
  const [linked, setLinked] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const {
    state,
    isPending,
    error,
    call: updateUser,
    clearState,
  } = useApi({
    route: `users/editUser/${User().get()?.user?.user_id}`,
    method: "POST",
    callback: (res) => {
      console.log("res", res.tournaments);
      if (res.hasError) {
        toast.error(res.message);
      } else {
        toast.success('Updated successfully')
      }
    },
  });

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      token: User().get()?.user?.token,
    };
    setFirstName(User().get()?.user?.first_name);
    setLastName(User().get()?.user?.last_name);
    setEmail(User().get()?.user?.email);
    setNumber(User().get()?.user?.phone);
    setTwitter(User().get()?.user?.Twitter);
    setInsta(User().get()?.user?.Instagram);
    setFacebook(User().get()?.user?.Facebook);
    setCountry(User().get()?.user?.Country);
    setLinked(User().get()?.user?.Linkedin);
    setUsername(User().get()?.user?.user_name);
  }, []);

  const handleSubmit = async () => {
    updateUser({
      body: {
        Email: email,
        Last_name: lastName,
        first_name: firstName,
        user_name: userName,
        Phone: number,
        Twitter: twitter,
        Instagram: insta,
        Linkedin: linked,
        Facebook: facebook,
        Country: country,
      },
    });

  };
  return (
    <Style>
      {isPending && <Loader />}
      <form className="game-form">
        <div className="emp" />
        <div className="wrapper">
          <div className="input-data">
            <input
              type="text"
              required
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            ></input>

            <label>First Name</label>
            <div className="underline"></div>
          </div>

          <div className="input-data">
            <input
              type="text"
              required
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            ></input>

            <label>Last Name</label>
            <div className="underline"></div>
          </div>

          <div className="input-data">
            <input
              type="text"
              required
              onChange={(e) => setUsername(e.target.value)}
              value={userName}
            ></input>

            <label>User Name</label>
            <div className="underline"></div>
          </div>

          <div className="input-data">
            <input
              type="text"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            ></input>

            <label>Email</label>
            <div className="underline"></div>
          </div>

          <div className="input-data">
            <input
              type="text"
              required
              onChange={(e) => setNumber(e.target.value)}
              value={number}
            ></input>

            <label>Phone Number</label>
            <div className="underline"></div>
          </div>

          <div className="input-data">
            <input
              type="text"
              required
              onChange={(e) => setCountry(e.target.value)}
              value={country}
            ></input>

            <label>Country</label>
            <div className="underline"></div>
          </div>

          <div className="input-data">
            <input
              type="text"
              required
              onChange={(e) => setFacebook(e.target.value)}
              value={facebook}
            ></input>

            <label>Facebook Link</label>
            <div className="underline"></div>
          </div>

          <div className="input-data">
            <input
              type="text"
              required
              onChange={(e) => setInsta(e.target.value)}
              value={insta}
            ></input>

            <label>Instagram Link</label>
            <div className="underline"></div>
          </div>

          <div className="input-data">
            <input
              type="text"
              required
              onChange={(e) => setTwitter(e.target.value)}
              value={twitter}
            ></input>

            <label>Twitter Link</label>
            <div className="underline"></div>
          </div>

          <div className="input-data">
            <input
              type="text"
              required
              onChange={(e) => setLinked(e.target.value)}
              value={linked}
            ></input>

            <label>LinkedIn Link</label>
            <div className="underline"></div>
          </div>
        </div>
        <div className="but">
          <Button className="buty" onClick={handleSubmit}>
            Update
          </Button>
        </div>
      </form>
    </Style>
  );
}
