import styled from "styled-components";
type Display = {
  active: boolean;
};
export const Div = styled.div<Display>`
  .con {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000;
    background-color: white;
    width: 300px;
    padding: 20px;
    background-color: #252f39;
    border-radius: 10px;
    display: ${(props) => (props.active ? 'block' : 'none')};
  }

  .buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 30px;
    justify-content: space-between;
    .btn1,
    .btn2 {
      width: 100px;
    }
  }
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(
      0,
      0,
      0,
      0.7
    ); /* Adjust the alpha (last value) for transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    display: ${(props) => (props.active ? 'block' : 'none')};
  }
`;
