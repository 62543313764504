import { Div } from "./style";
import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import Fixture from "../Components/Fixture";
import UserCard from "../Components/UserCard";
import { useApi } from "../../../api";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ButtonLoader from "../../../components/Loader";
import { User } from "../../../User";

type Iprop = {
  // type: string;
  // value: any;
  users: any;
  done: any;
  tournament?: any;
};

const Register = ({ tournament, done, users: alUsers }: Iprop) => {
  const { id } = useParams();

  const [users, setUsers] = useState<any>([...alUsers]);
  const [selectedUsers, setSelectedUsers] = useState<any>([
    {
      UserName: User()?.get()?.user.user_name,
      GameUserName: User()?.get()?.user.user_name,
      User_id: User()?.get()?.user._id,
      Email: User()?.get()?.user.email,
      FirstName: User()?.get()?.user.first_name,
      LastName: User()?.get()?.user.last_name,
    },
  ]);
  const [page, setPage] = useState<number>(1);
  const [teamName, setTeamName] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [showIcn, setShowIcn] = useState<boolean>(false);
  const [icns] = useState<any>([
    "https://ik.imagekit.io/dmkczkqll/Gamelyd/Tournaments/acane.png?updatedAt=1695897392507",
    "https://ik.imagekit.io/dmkczkqll/Gamelyd/Tournaments/hacker.png?updatedAt=1695897392790",
    "https://ik.imagekit.io/dmkczkqll/Gamelyd/Tournaments/codm.png?updatedAt=1695897346299",
    "https://ik.imagekit.io/dmkczkqll/Gamelyd/Tournaments/girl.png?updatedAt=1695897324556",
    "https://ik.imagekit.io/dmkczkqll/Gamelyd/Tournaments/warzone.png?updatedAt=1695897395102",
  ]);

  const { state, isPending, error, call, clearState } = useApi({
    route: `users/search/${search}/${page}`,
    method: "GET",
    callback: (res: any) => {
      console.log("res", res);
      if (res.hasError) {
        toast.error(res.message);
      } else {
        setUsers(res.users);
        console.log("users", res);
      }
    },
  });

  const {
    state: stateSave,
    isPending: isPendingSave,
    error: errorSave,
    call: callSave,
    clearState: clearStateSave,
  } = useApi({
    route: `tournament/register/${id}`,
    method: "POST",
    callback: (res: any) => {
      console.log("res", res);
      if (res.hasError) {
        toast.error(res.message);
      } else {
        setShowIcn(false);
        done();
        // setUsers(res.users);
        // console.log("users", res);
      }
    },
  });

  const submit = (icn: string) => {
    callSave({
      body: {
        TeamName: teamName,
        Icon: icn,
        Players: [...selectedUsers],
      },
    });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      call();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    console.log("selectedUsers", User()?.get()?.user);
    // Additional logic based on the updated state
  }, [selectedUsers]);

  useEffect(() => {
    if (users.length === 0) {
      call();
    }
  }, []);
  return (
    <Div>
      {isPendingSave ? <ButtonLoader /> : null}
      <div className="con">
        <div className="in">
          <input
            type="text"
            value={teamName}
            onChange={(e) => {
              setTeamName(e.target.value);
            }}
            placeholder="Team Name"
          />
          <div className="but">
            <Button
              theme="dark"
              onClick={() => {
                if (selectedUsers.length < 1) {
                  toast.error("No players selected");
                  return;
                }
                if (!teamName) {
                  toast.error("team name is required");
                  return;
                }

                setShowIcn(true);
              }}
            >
              Register
            </Button>
          </div>
        </div>

        <div className="team">
          <div className="tit">Select Teammates</div>
          <input
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="Search Username"
          />{" "}
          <div className="selected">
            {selectedUsers.map((user: any, i: number) => (
              <div className="select">
                <div className="name">{user.FirstName}</div>
                <div className="cancel">
                  <img
                    src="https://ik.imagekit.io/dmkczkqll/Gamelyd/Icons/cancel.svg?updatedAt=1702198051653"
                    alt="remove"
                    onClick={() => {
                      if (User()?.get()?.user.email === user.Email) {
                        toast.error("you can't remove your self from the team");
                        return;
                      }
                      setSelectedUsers((prevSelectedUsers: any) =>
                        prevSelectedUsers.filter(
                          (selectedUser: any) =>
                            selectedUser.Email !== user.Email
                        )
                      );
                    }}
                  />
                </div>
                <div className="userName">@{user.UserName}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="users">
          {users.map((user: any, i: number) => (
            <div className="user">
              <UserCard
                selectUser={(user: any) => {
                  const isSelected = selectedUsers.some(
                    (selectedUser: any) => selectedUser.Email === user.email
                  );
                  console.log("isSeleted", isSelected, user._id);

                  if (
                    tournament.Team === "SINGLE" &&
                    selectedUsers.length === 1
                  ) {
                    toast.error(
                      "this is a single tournament mode, maximum capacity reached"
                    );
                    return;
                  } else if (
                    tournament.Team === "DUO" &&
                    selectedUsers.length === 2
                  ) {
                    toast.error(
                      "this is a two players tournament mode, maximum capacity reached"
                    );
                    return;
                  }
                  if (isSelected) {
                    toast.error("user already selected");
                    return;
                  }
                  setSelectedUsers((prevSelectedUsers: any) => [
                    ...prevSelectedUsers,
                    {
                      UserName: user.user_name,
                      GameUserName: user.user_name,
                      User_id: user._id,
                      Email: user.email,
                      FirstName: user.first_name,
                      LastName: user.last_name,
                    },
                  ]);
                }}
                user={user}
                key={i}
              />
            </div>
          ))}
        </div>
      </div>

      {showIcn && (
        <>
          <div className="winner">
            <div className="bx">Select Team Icon</div>
            <div className="ims">
              {icns.map((icn: any, i: number) => (
                <img
                  className="icn"
                  src={icn}
                  alt="icn"
                  onClick={() => submit(icn)}
                  key={i}
                />
              ))}
            </div>
          </div>
          <div
            className="bg"
            onClick={() => {
              setShowIcn(false);
            }}
          ></div>
        </>
      )}
    </Div>
  );
};
export default Register;
