import { useRef, useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import ImageSlider from "../Slider/ImageSlider";
import Text from "../Text/Text";
import { HeroStyle } from "./style";
import open from "../../assets/audios/Open.mp3";
import Button from "../Button";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { User } from "../../User";
import TournamentDisplay from "../TournamentDisplay";
import image from "../../assets/images/cod.png";
import { useApi } from "../../api";
import EmptyTournamentDisplay from "../TournamentDisplay/empty";

const Hero = (props) => {
  const modalRef = useRef();
  const [tournaments, setTournaments] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const search = location.search;
  const userInfo = User().get();

  const { state, isPending, error, call, clearState } = useApi({
    route: `tournament/mode/SPONSORED/MULTIPLAYER/${1}`,
    method: "GET",
    callback: (res) => {
      if (res.hasError) {
        toast.error(res.message);
      } else {
        console.log("res", res);
        const data = res.tournaments;
        for (let i = 0; i < data.length; i++) {
          if (data[i].date === "today" || data[i].date === "TODAY") {
            data[i].date = "";
          }
        }
        setTournaments(res.tournaments ? data : []);
        console.log('tournaments', res.tournaments);
      }
    },
  });

  useEffect(() => {
    call();
  }, []);

  const create = () => {
    if (userInfo?.user) {
      modalRef.current.open();

      let audio = new Audio(open);
      audio.play();
    } else {
      toast.info("You need to login to create tournament");
      setTimeout(() => {
        navigate(`/signup?${location.pathname}`);
      }, 1000);
    }
  };

  const close = () => {
    modalRef.current.close();
  };

  useEffect(() => {
    const open = new URLSearchParams(search).get("open");

    if (open) {
      setTimeout(() => {
        modalRef.current.open();
      }, 1000);
    }
  });
  return (
    <HeroStyle background={props.background}>
      <div className="tons">
        <div className="card">
          {tournaments.length < 1 && <EmptyTournamentDisplay />}
          {tournaments.length > 0 && (
            <TournamentDisplay data={tournaments[0]} background={image} />
          )}
        </div>
        <div className="card">
          {tournaments.length < 2 && <EmptyTournamentDisplay />}
          {tournaments.length > 1 && (
            <TournamentDisplay data={tournaments[1]} background={image} />
          )}{" "}
        </div>
      </div>
    </HeroStyle>
  );
};

export default Hero;
