import React, { useState } from "react";
import { Div } from "./style";
import Tag from "../Tag";
import Button from "../Button";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";

type IProp = {
  //   message: string
  //   time : string
  background: string;
  data: any;
};

const TournamentDisplay = (props: IProp): any => {
  console.log("data", props.data);
  const [image, setImage] = useState(
    props?.data?.icon
      ? props?.data?.icon
      : "https://ik.imagekit.io/dmkczkqll/Gamelyd/Tournaments/warzone2.jpeg?updatedAt=1695930036202"
  );

  return (
    <Div
      background={
        image?.startsWith("https:")
          ? image
          : "https://ik.imagekit.io/dmkczkqll/Gamelyd/Tournaments/warzone2.jpeg?updatedAt=1695930036202"
      }
    >
      <div className="top">
        <div className="paid">
          <div className="feeBox">
            <Tag content={props?.data?.payment} background="primary" />
            <div className="fee">
              <div className="feeText">Entry Fee</div>
              <div className="feeAmount">
                {props?.data?.isPaid ? "₦" + props.data.registrationamount : "₦0"}
              </div>
            </div>
          </div>
          {props?.data?.payment === "SPONSORED" && (
            <div className="prize">
              <div className="prizeText">Prize</div>
              <div className="prizeAmount">
                {props.data.amount ? "₦" + props.data.amount : "₦0"}
              </div>
            </div>
          )}
          {props?.data?.payment === "PAID" && (
            <div className="prize">
              <div className="prizeText">Prize</div>
              <div className="prizeAmount">
                {props?.data?.amount ? "₦" + props?.data?.amount : "₦0"}
              </div>
            </div>
          )}
        </div>
        <div className="plat">
          <div className="platform">
            {props?.data?.name}
            {"   ("} {props?.data?.gamename}
            {")"}
          </div>
          <div className="platform">
            {props?.data?.Plartform ? props?.data?.Platform : "Cross Platform"}{" "}
            {props?.data?.tournamentmode === "MULTIPLAYER" ? "(MP)" : "(BR)"}
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="infoBox">
          <div className="date">
            {props?.data?.date
              ? format(new Date(props.data.date), "EEEE, do MMMM")
              : "coming soon"}
          </div>
          <div className="info">winner takes all</div>
        </div>
        {/* <div className="time">9pm WAT</div> */}
        <NavLink to={`/tournament/${props?.data?._id}`} className="">
          <Button>ENTER TOURNAMENT</Button>
        </NavLink>
      </div>
    </Div>
  );
};

export default TournamentDisplay;
