import { Div } from "./style";
import { useState } from "react";

type Iprop = {
  // type: string;
  // value: any;
  // onChange: any;
  // label: string;
  text?: any;
};

const Uicon = ({ text }: Iprop) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <Div onClick={() => setShow(!show)}>
      {text}
    </Div>
  );
};
export default Uicon;
