import { Div } from "./style";
import { useState } from "react";
import { motion } from "framer-motion";
import Uicon from "../../../../components/Uicon";

type Iprop = {
  // type: string;
  // value: any;
  // onChange: any;
  selectUser: any;
  user?: any;
};

const UserCard = ({ user, selectUser }: Iprop) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <Div onClick={() => selectUser(user)}>
      <div className="image">
        {/* <img
          src="https://ik.imagekit.io/dmkczkqll/Gamelyd/Icons/girl.png?updatedAt=1701589631511"
          alt="user icon"
        /> */}
        <Uicon text={`${user?.first_name ? user?.first_name.charAt(0) : 'A'}${user?.last_name ? user?.last_name.charAt(0) : 'B'}`} />
      </div>
      <div className="name">
        <div className="main">{user?.first_name} {' '} {user?.last_name}</div>
        <div className="userName">@{user?.user_name}</div>
      </div>
    </Div>
  );
};
export default UserCard;
