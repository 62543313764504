import NotFound from "../../../components/NotFound";
import TeamCard from "../Components/TeamCard";
import { Div } from "./style";
import { useState } from "react";

type Iprop = {
  // type: string;
  // value: any;
  // onChange: any;
  // label: string;
  data?: any;
};

const Teams = ({ data }: Iprop) => {
  return (
    <Div>
      {!data ||
        (data.length === 0 && (
          <>
            <NotFound text="No registered teams yet" />
          </>
        ))}
      {data.map((item: any, i: number) => (
        <TeamCard item={item} key={i} />
      ))}
    </Div>
  );
};
export default Teams;
