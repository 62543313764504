import { Div } from "./style";
import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import Fixture from "../Components/Fixture";
import NotFound from "../../../components/NotFound";

type Iprop = {
  // type: string;
  // value: any;
  // onChange: any;
  // label: string;
  draws: any[];
};

const Fixtures = ({ draws }: Iprop) => {
  const [data, setData] = useState<any>([]);
  console.log(draws);

  const formatData = () => {
    // Use reduce to group objects by stage
    const groupedObjects = draws.reduce((acc, obj) => {
      const { stage } = obj;

      // Create an array for the stage if it doesn't exist
      acc[stage] = acc[stage] || [];

      // Push the object to the corresponding stage array
      acc[stage].push(obj);
      setData(acc);

      return acc;
    }, {});
    console.log("data", data);
  };

  useEffect(() => {
    if (draws) {
      formatData();
    }
  }, [draws]);

  return (
    <Div>
      <div className="pages">
        
          {!Object.entries(data) ||
            (Object.entries(data).length === 0 && (
              <>
                <NotFound text="No draws available, come back later" />
              </>
            ))}
        {Object.entries(data).map((item: any, i: number) => (
          <div className="games" key={i}>
            <div className="top">
              {item[1].length < 2
                ? "FINALS"
                : item[1].length < 3
                ? "SEMI FINALS"
                : item[1].length < 5
                ? "QUARTER FINALS"
                : item[1].length < 9
                ? "ROUND OF SIXTEEN"
                : `ROUND  ${item[0]}`}
            </div>
            {item[1].map((list: any, index: number) => (
              <div key={index}>
                <div className="game">
                  <Fixture isFinal={item[1].length < 2 ? true : false} data={list} />
                </div>
              </div>
            ))}
            {console.log("item", item)}
          </div>
        ))}
      </div>
    </Div>
  );
};
export default Fixtures;
