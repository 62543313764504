import { Phone } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { Style } from "./style";
import Loader from "../Loader";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { User } from "../../User";
import Button from "../../components/Button";
import { useApi } from "../../api";

export default function ChangePassword() {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [type1, setType1] = useState("password");
  const [type2, setType2] = useState("password");
  const [type3, setType3] = useState("password");

  const {
    state,
    isPending,
    error,
    call: updatePassword,
    clearState,
  } = useApi({
    route: `users/changePassword/${User().get()?.user?.user_id}`,
    method: "POST",
    callback: (res) => {
      console.log("res", res.tournaments);
      if (res.hasError) {
        toast.error(res.message);
      } else {
        toast.success("Updated successfully");
      }
    },
  });

  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {}, []);

  const handleSubmit = async () => {

    if (!password) {
      toast.error("password is required");
      setLoading(false);
      return;
    }
    if (!newPassword) {
      toast.error("New password is required");
      setLoading(false);
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("New password does not match confirm password");
      setLoading(false);
      return;
    }

    updatePassword({
      body: {
        Password: password,
        NewPassword: newPassword,
      },
    });

  };
  return (
    <Style>
      {isPending && <Loader />}
      <form className="game-form">
        <div className="emp" />
        <div className="wrapper">
          <div className="input-data">
            <input
              type={type1}
              required
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            ></input>

            <label>Password</label>
            <div className="underline"></div>
            {type1 === "text" && (
              <VisibilityOffIcon
                onClick={() =>
                  setType1(type1 === "password" ? "text" : "password")
                }
                style={{ color: "rgb(11, 192, 180)" }}
                className="eye"
              />
            )}
            {type1 === "password" && (
              <VisibilityIcon
                onClick={() =>
                  setType1(type1 === "password" ? "text" : "password")
                }
                style={{ color: "white" }}
                className="eye"
              />
            )}
          </div>

          <div className="input-data">
            <input
              type={type2}
              required
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
            ></input>

            <label>New Password</label>
            <div className="underline"></div>
            {type2 === "text" && (
              <VisibilityOffIcon
                onClick={() =>
                  setType2(type2 === "password" ? "text" : "password")
                }
                style={{ color: "rgb(11, 192, 180)" }}
                className="eye"
              />
            )}
            {type2 === "password" && (
              <VisibilityIcon
                onClick={() =>
                  setType2(type2 === "password" ? "text" : "password")
                }
                style={{ color: "white" }}
                className="eye"
              />
            )}
          </div>

          <div className="input-data">
            <input
              type={type3}
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            ></input>

            <label>Confirm Password</label>
            <div className="underline"></div>
            {type3 === "text" && (
              <VisibilityOffIcon
                onClick={() =>
                  setType3(type3 === "password" ? "text" : "password")
                }
                style={{ color: "rgb(11, 192, 180)" }}
                className="eye"
              />
            )}
            {type3 === "password" && (
              <VisibilityIcon
                onClick={() =>
                  setType3(type3 === "password" ? "text" : "password")
                }
                style={{ color: "white" }}
                className="eye"
              />
            )}
          </div>
        </div>
        <div className="but">
          <Button className="buty" onClick={handleSubmit}>
            Update
          </Button>
        </div>
      </form>
    </Style>
  );
}
