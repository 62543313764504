import Footer from "../../components/Footer/Footer";
import TournamentDisplay from "../../components/TournamentDisplay";
import next from "../../assets/icons/pageNext.svg";
import prev from "../../assets/icons/pagePrev.svg";
import { Div } from "./style";
import Button from "../../components/Button";
import Navbar from "../../components/NavBar/Navbar";
import Dropdown from "../../components/Dropdown";
import filter from "../../assets/icons/filter.svg";
import { toast } from "react-toastify";
import { useApi } from "../../api";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ButtonLoader from "../../components/Loader";
import NotFound from "../../components/NotFound";

// type Iprop = {
//   // type: string;
//   // value: any;
//   // onChange: any;
//   // label: string;
//   // required?: boolean;
// };

const Tournaments = () => {
  const navigate = useNavigate();
  const { mode, type } = useParams();

  const [tournaments, setTournaments] = useState([]);
  const [info, setInfo] = useState<any>({});
  const [page, setPage] = useState(1);

  const { state, isPending, error, call, clearState } = useApi({
    route: `tournament/mode/${mode}/${type}/${page}`,
    method: "GET",
    callback: (res: any) => {
      if (res.hasError) {
        toast.error(res.message);
      } else {
        console.log("res", res);
        const data = res.tournaments;
        for (let i = 0; i < data.length; i++) {
          if (data[i].date === "today" || data[i].date === "TODAY") {
            data[i].date = "";
          }
        }
        setTournaments(res.tournaments ? data : []);
        setInfo(res);
      }
    },
  });

  useEffect(() => {
    call();
  }, [mode, type, page]);

  return (
    <Div>
      <Navbar message="jh" />
      {isPending && <ButtonLoader />}
      <div className="content">
        <div className="head">
          <div className="filter">
            <div className="fil">
              <div className="filterBack">
                <img className="filImage" src={filter} alt="filter" />
              </div>
              <Dropdown
                setData={(data: string | number) =>
                  navigate(`/tournament/all/${data}/${type}`)
                }
                options={["FREE", "PAID", "SPONSORED"]}
                value={mode as string}
              />
            </div>
            <div className="drop">
              <Dropdown
                setData={(data: string | number) =>
                  navigate(`/tournament/all/${mode}/${data}`)
                }
                options={["MULTIPLAYER", "BATTLEROYALE"]}
                value={type as string}
              />
            </div>
          </div>
          <Button theme="dark">Create Tournament</Button>
        </div>

        <div className="body">
          {!tournaments ||
            (tournaments.length === 0 && (
              <>
                <NotFound text="No tournament found" />
              </>
            ))}
          <>
            {tournaments.map((item, i) => (
              <TournamentDisplay
                background={
                  "https://ik.imagekit.io/dmkczkqll/Gamelyd/Tournaments/warzone2.jpeg?updatedAt=1695930036202"
                }
                data={item}
                key={i}
              />
            ))}
          </>
        </div>
        <div className="pagination">
          <img
            src={prev}
            className={`${page === 1 ? "disable" : null}`}
            alt="icn"
            onClick={() => (page !== 1 ? setPage((prev) => prev - 1) : null)}
          />
          <div className="page">{page}</div>
          <img
            src={next}
            className={`${info.last_page === page ? "disable" : null}`}
            alt="icn"
            onClick={() =>
              info.last_page !== page ? setPage((prev) => prev + 1) : null
            }
          />
        </div>
      </div>
      <Footer />
    </Div>
  );
};
export default Tournaments;
