import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ModalStyle } from "./style";
import { motion, AnimatePresence } from "framer-motion";
import img from "../../assets/icons/ntn.svg";

const NotFound = (props: any) => {
  return (
    <AnimatePresence>
      <ModalStyle>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.3,
            },
          }}
          className="modal-backdrop"
        />
        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              duration: 0.3,
            },
          }}
          className="modal-content-wrapper"
        >
          <div className="con">
            <div className="both">
              <img src={img} alt="empty" />
              <div className="text">{props.text}</div>
            </div>
          </div>
        </motion.div>
      </ModalStyle>
    </AnimatePresence>
  );
};

export default NotFound;
