import { Div } from "./style";
import Button from "../../../components/Button";
import filter from "../../assets/icons/filter.svg";
import { format } from "date-fns";

type Iprop = {
  // type: string;
  // value: any;
  // onChange: any;
  // label: string;
  data?: any;
};

const Details = ({data} : Iprop) => {
  return (
    <Div>
      <div className="con">
        <table>
          <tbody>
            <tr>
              <th>Tournament Name</th>
              <td>{data.Name ? data.Name : null}</td>
            </tr>
            <tr>
              <th>Game Name</th>
              <td>{data.GameName ? data.GameName : null}</td>
            </tr>
            <tr>
              <th>Payment Type</th>
              <td>{data.Payment ? data.Payment : null}</td>
            </tr>
            <tr>
              <th>Platform</th>
              <td>{data.Platform ? data.Platform : null}</td>
            </tr>
            <tr>
              <th>Mode</th>
              <td>{data.TournamentMode ? data.TournamentMode : null}</td>
            </tr>
            <tr>
              <th>Type</th>
              <td>{data.TournamentType ? data.TournamentType : null}</td>
            </tr>
            <tr>
              <th>Start Date</th>
              <td>{data.Date ? format(new Date(data.Date), 'EEEE, do MMMM, yyyy') : null}</td>
            </tr>
            <tr>
              <th>Size</th>
              <td>{data.TournamentSize ? data.TournamentSize : null} teams</td>
            </tr>
            <tr>
              <th>Team Size</th>
              <td>{data.Team ? data.Team : null}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Div>
  );
};
export default Details;
