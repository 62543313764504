import React, { useEffect, useState } from 'react';
import './style.css';

interface Ribbon {
  id: number;
  left: string;
  top: number;
  speed: number;
  color: string;
  size: number;
  isVisible: boolean;
}

const FallingRibbons: React.FC = () => {
  const [ribbons, setRibbons] = useState<Ribbon[]>([]);
  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    const newRibbons = Array.from({ length: 60 }, (_, index) => ({
      id: index,
      left: `${Math.random() * 100}vw`,
      top: 0,
      speed: Math.random() * 2 + 1,
      color: getRandomColor(),
      size: Math.random() * 2 + 1,
      isVisible: visible,
    }));

    setRibbons(newRibbons);

    const fallingInterval = setInterval(() => {
      setRibbons((prevRibbons) =>
        prevRibbons.map((ribbon) => {
          const newTop = ribbon.top + ribbon.speed;
          return newTop >= window.innerHeight
            ? { ...ribbon, top: 0 }
            : { ...ribbon, top: newTop };
        })
      );
    }, 30);

    setTimeout(() => {
      clearInterval(fallingInterval);
      setRibbons((prevRibbons) => prevRibbons.map((ribbon) => ({ ...ribbon, isVisible: false })));
    }, 40000);

    return () => clearInterval(fallingInterval);
  }, []);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <>
      {ribbons.map(
        (ribbon) =>
          ribbon.isVisible && (
            <div
              key={ribbon.id}
              className="ribbon"
              style={{
                left: ribbon.left,
                top: `${ribbon.top}px`,
                backgroundColor: ribbon.color,
                transform: `rotate(${Math.random() * 360}deg)`,
              }}
            />
          )
      )}
    </>
  );
};

export default FallingRibbons;
