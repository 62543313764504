import Footer from "../../components/Footer/Footer";
import TournamentDisplay from "../../components/TournamentDisplay";
import next from "../../assets/icons/pageNext.svg";
import prev from "../../assets/icons/pagePrev.svg";
import { Div } from "./style";
import Button from "../../components/Button";
import Navbar from "../../components/NavBar/Navbar";
import Dropdown from "../../components/Dropdown";
import filter from "../../assets/icons/filter.svg";
import Details from "./Details";
import { useEffect, useState } from "react";
import Fixtures from "./Fixtures";
import Teams from "./Teams";
import Register from "./Register";
import { useApi } from "../../api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonLoader from "../../components/Loader";
import Confirm from "../../components/Confirm";
import SuccessFall from "../../components/SuccessFall";
import TeamCard from "./Components/TeamCard";
import { User } from "../../User";
import { log } from "console";

// type Iprop = {
//   // type: string;
//   // value: any;
//   // onChange: any;
//   // label: string;
//   // required?: boolean;
// };

const TournamentView = () => {
  const [active, setActive] = useState<string>("details");
  const [tournament, setTournament] = useState<any>({});
  const [participants, setParticipants] = useState<any[]>([]);
  const [draws, setDraws] = useState<any[]>([]);
  const [currentStage, setCurrentStage] = useState<number>(1);
  const [drawActive, setDrawActive] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [unreg, setUnreg] = useState<boolean>(false);
  const [drawsData, setDrawsData] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const { id } = useParams();

  const formatData = (drawsAll: any) => {
    const highestStage = drawsAll.reduce((maxStage: any, obj: any) => {
      return obj.stage > maxStage ? obj.stage : maxStage;
    }, -Infinity);
    setCurrentStage(highestStage);
    console.log("highest", highestStage);
  };

  const { state, isPending, error, call, clearState } = useApi({
    route: `tournament/${id}`,
    method: "GET",
    callback: (res: any) => {
      console.log("res", res);
      if (res.hasError) {
        toast.error(res.message);
      } else {
        setTournament(res.tournament);
        if (res?.tournament?.Winner?.TeamName) {
          setShowSuccess(true);
        }
      }
    },
  });

  const { state: userState, isPending: userPending, error: userError, call: getUsers, clearState: userClearState } = useApi({
    route: `users/search//1`,
    method: "GET",
    callback: (res: any) => {
      console.log("res", res);
      if (res.hasError) {
        toast.error(res.message);
      } else {
        setUsers(res.users);
        console.log("users", res);
      }
    },
  });

  const {
    state: drawState,
    isPending: drawPending,
    error: drawError,
    call: drawCall,
    clearState: drawClear,
  } = useApi({
    route: `draws/${id}`,
    method: "GET",
    callback: (res: any) => {
      if (res.hasError) {
        toast.error(res.message);
      } else {
        setDraws(res.draws);
        console.log("draws", draws);
        formatData(res.draws);
      }
    },
  });

  const {
    state: drawTempState,
    isPending: drawTempPending,
    error: drawTempError,
    call: drawTempCall,
    clearState: drawTempClear,
  } = useApi({
    route: `draws/${id}`,
    method: "GET",
    callback: (res: any) => {
      if (res.hasError) {
        toast.error(res.message);
      } else {
        setDraws(res.draws);
        if (res.draws) {
          formatData(res.draws);
        }
        console.log("draws", draws);

        if (participants === null || participants.length === 0) {
          toast.error("No participants yet");
          return;
        }
        let len = res.draws ? res.draws.length : 0;
        console.log("len", len, participants);

        if (len === 0) {
          setDrawActive(true);
        } else {
          for (let i = 0; i < res.draws.length; i++) {
            if (!res.draws[i].Winner) {
              console.log("res.draws[i]", res.draws[i]);

              if (res.draws[i].team2.teamname !== "Automatic Qualification") {
                toast.error("some draws dont have scores");
                return;
              }
            }
            if (res.draws[i].Team1Score === res.draws[i].Team2Score) {
              if (res.draws[i].team2.teamname !== "Automatic Qualification") {
                toast.error(
                  "There has to be a winner for each draw, some draws are stalemate"
                );
                return;
              }
            }
          }
          drawSave({
            body: {
              TournamentId: id,
              Stage: res.draws.length ? currentStage + 1 : 1,
            },
          });
        }
      }
    },
  });

  const {
    state: drawSaveState,
    isPending: drawSavePending,
    error: drawSaveError,
    call: drawSave,
    clearState: drawSaveClear,
  } = useApi({
    route: `draws/save`,
    method: "POST",
    callback: (res: any) => {
      console.log("draw call", res);
      if (res.hasError) {
        toast.error(res.message);
      } else {
        // setTournament(res.tournament);
        call();
        setActive("fixtures");
        setReload((prev) => !prev);
      }
    },
  });

  const {
    state: partState,
    isPending: partPending,
    error: partError,
    call: partCall,
    clearState: partClear,
  } = useApi({
    route: `tournament/participants/${id}`,
    method: "GET",
    callback: (res: any) => {
      console.log("participants call", res);
      if (res.hasError) {
        toast.error(res.message);
      } else {
        if (res.tournament) {
          setParticipants(res.tournament);
        }
      }
    },
  });

  const {
    state: stateRemove,
    isPending: isPendingRemove,
    error: errorRemove,
    call: callRemove,
    clearState: clearStateRemove,
  } = useApi({
    route: `tournament/removeFromTournament/${User()?.get()?.user._id}/${id}`,
    method: "GET",
    callback: (res: any) => {
      console.log("res", res);
      if (res.hasError) {
        toast.error(res.message);
      } else {
        toast.error("you have been removed from this tournament");
      }
    },
  });

  const draw = () => {};

  useEffect(() => {
    call();
    drawCall();
    partCall();
    getUsers()
  }, [id, reload]);

  return (
    <Div background={tournament.icon}>
      <Navbar message="jh" />
      {isPending ||
      drawPending ||
      drawSavePending ||
      partPending ||
      isPendingRemove ||
      drawTempPending ? (
        <ButtonLoader />
      ) : null}
      <div className="page">
        <div className="header"></div>
        <div className="buttons">
          <div className="but">
            <Button
              theme={active === "details" ? "active" : "green"}
              onClick={() => setActive("details")}
            >
              Tournament Details
            </Button>
          </div>

          <div className="but">
            <Button
              theme={active === "fixtures" ? "active" : "green"}
              onClick={() => setActive("fixtures")}
            >
              Fixtures
            </Button>
          </div>
          <div className="but">
            <Button
              theme={active === "teams" ? "active" : "green"}
              onClick={() => setActive("teams")}
            >
              All Teams
            </Button>
          </div>
          <div className="but">
            <Button
              theme={active === "register" ? "active" : "green"}
              onClick={() => setActive("register")}
            >
              Register
            </Button>
          </div>
          <div className="but">
            <Button theme={"green"} onClick={() => drawTempCall()}>
              Draw
            </Button>
          </div>
          <div className="but" onClick={() => setUnreg(true)}>
            <Button theme="danger">Unregister</Button>
          </div>
        </div>
        {!isPending && (
          <div className="body">
            {active === "details" && <Details data={tournament} />}
            {active === "fixtures" && <Fixtures draws={draws} />}
            {active === "teams" && <Teams data={participants} />}
            {active === "register" && (
              <Register
                done={() => {
                  partCall();
                  setActive("teams");
                }}
                users={users}
                tournament={tournament}
              />
            )}
          </div>
        )}
      </div>

      <Footer />
      <Confirm
        close={() => setDrawActive(false)}
        confirm={() => {
          setDrawActive(false);
          if (participants.length < 2) {
            toast.error("You can't draw with less than two teams");
          } else {
            drawSave({
              body: {
                TournamentId: id,
                Stage: !draws || draws.length === 0 ? 1 : currentStage + 1,
              },
            });
          }
        }}
        active={drawActive}
        text={`${participants.length} team(s) registered, Registration closes as soon as you make your first draw. Do you still wish to continue?`}
      />

      <Confirm
        close={() => setUnreg(false)}
        confirm={() => {
          setUnreg(false);
          callRemove();
        }}
        active={unreg}
        text={`are you sure you want to leave your squad? clicking ok will remove you from this tournament`}
      />
      {showSuccess && <SuccessFall />}
      {showSuccess && (
        <>
          <div className="winner">
            <div className="bx">
              <div className="tx">Winner</div>
              <div className="close" onClick={() => setShowSuccess(false)}>
                {" "}
                close
              </div>
            </div>
            <TeamCard item={tournament?.Winner} />
          </div>
          <div className="bg" onClick={() => setShowSuccess(false)}></div>
        </>
      )}

      {showSuccess && (
        <>
          <div className="winner">
            <div className="bx">
              <div className="tx">Winner</div>
              <div className="close" onClick={() => setShowSuccess(false)}>
                {" "}
                close
              </div>
            </div>
            <TeamCard item={tournament?.Winner} />
          </div>
          <div className="bg" onClick={() => setShowSuccess(false)}></div>
        </>
      )}
    </Div>
  );
};
export default TournamentView;
