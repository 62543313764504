import { Div } from "./style";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../api";
import ButtonLoader from "../../../../components/Loader";
import { format } from "date-fns";

type Iprop = {
  // type: string;
  // value: any;
  isFinal: boolean;
  data?: any;
};

const Fixture = ({ data, isFinal }: Iprop) => {
  const { id } = useParams();
  const [team1score, setTeam1score] = useState<number>(0);
  const [team2score, setTeam2score] = useState<number>(0);
  const [displayTime, setDisplayTime] = useState<string>("");
  const [displayDate, setDisplayDate] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [date, setDate] = useState<any>("");
  const [time, setTime] = useState<any>("");
  const [selectedHour, setSelectedHour] = useState<any>("12");
  const [selectedSeconds, setSelectedSeconds] = useState<any>("00");
  const [periods, setPeriods] = useState<any>(["AM", "PM"]);
  const [period, setPeriod] = useState<any>("AM");
  const [hours, setHours] = useState<any>([
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ]);
  const [seconds, setSeconds] = useState<any>([
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "60",
  ]);
  const [isEditScore, setIsEditScore] = useState<boolean>(false);
  const [isEditTime, setIsEditTime] = useState<boolean>(false);
  const [isEditLink, setIsEditLink] = useState<boolean>(false);

  const {
    state: stateLink,
    isPending: loadingLink,
    error: errorLink,
    call: callLink,
    clearState: clearStateLink,
  } = useApi({
    route: `draws/addLink/${data.drawid}`,
    method: "POST",
    callback: (res: any) => {
      if (res.hasError) {
        toast.error(res.message);
      } else {
        toast.success("Link added");
        clear();
      }
    },
  });

  const {
    state: stateScore,
    isPending: loadingScore,
    error: errorScore,
    call: callScore,
    clearState: clearStateScore,
  } = useApi({
    route: `draws/addScore/${data.drawid}`,
    method: "POST",
    callback: (res: any) => {
      if (res.hasError) {
        toast.error(res.message);
      } else {
        toast.success("Score added");
        clear();
      }
    },
  });

  const {
    state: stateTime,
    isPending: loadingTime,
    error: errorTime,
    call: callTime,
    clearState: clearStateTime,
  } = useApi({
    route: `draws/addTime/${data.drawid}`,
    method: "POST",
    callback: (res: any) => {
      if (res.hasError) {
        toast.error(res.message);
      } else {
        toast.success("Time added");
        clear();
      }
    },
  });

  const clear = () => {
    setIsEditScore(false);
    setIsEditTime(false);
    setIsEditLink(false);
  };

  useEffect(() => {
    setTeam1score(data.Team1Score ? +data.Team1Score : 0);
    setTeam2score(+data.Team2Score ? data.Team2Score : 0);
    setLink(data.Link ? data.Link : "");
    setDisplayDate(data.date);
    setDisplayTime(data.time);
    console.log("data  yea", data);
  }, [data, date]);

  return (
    <Div
      player1={
        data?.team1?.icon
          ? data?.team1?.icon
          : "https://ik.imagekit.io/dmkczkqll/Gamelyd/Icons/icon.png?updatedAt=1706418032616"
      }
      player2={
        data?.team2?.icon
          ? data?.team2?.icon
          : "https://ik.imagekit.io/dmkczkqll/Gamelyd/Icons/icon.png?updatedAt=1706418032616"
      }
    >
      {loadingLink || loadingScore || loadingTime ? <ButtonLoader /> : null}

      <div className="con">
        <div className="bts">
          {data.team2.teamname !== "Automatic Qualification" && (
            <div
              className="ntn"
              onClick={() => {
                setIsEditScore((prev) => !prev);
                setIsEditTime(false);
                setIsEditLink(false);
              }}
            >
              Add score
            </div>
          )}
          {data.team2.teamname !== "Automatic Qualification" && (
            <div
              className="ntn"
              onClick={() => {
                setIsEditTime((prev) => !prev);
                setIsEditScore(false);
                setIsEditLink(false);
              }}
            >
              Add time
            </div>
          )}
          {data.team2.teamname !== "Automatic Qualification" && (
            <div
              className="ntn"
              onClick={() => {
                setIsEditLink((prev) => !prev);
                setIsEditScore(false);
                setIsEditTime(false);
              }}
            >
              Add match link
            </div>
          )}
        </div>

        <AnimatePresence>
          <motion.div
            className="both"
            initial="hidden"
            whileInView="visible"
            // initial={{ x: "-100vw", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-100vw", opacity: 0 }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: {
                opacity: 0.9,
                // scale: 1,
                x: "100px",
                transition: {
                  type: "spring",
                  stiffness: 20,
                  damping: 5,
                },
              },
              hidden: { opacity: 0, scale: 1, x: 0 },
            }}
          >
            <div className="player1" />
            <div className="playerScore1">
              <div className="name">{data.team1.teamname}</div>
              <div
                className={`score ${
                  team1score > team2score
                    ? "scoreWin"
                    : team1score < team2score
                    ? "scoreLoose"
                    : "scoreDraw"
                }`}
              >
                {isEditScore ? (
                  <input
                    className="score-input"
                    value={team1score}
                    onChange={(e) => setTeam1score(+e.target.value)}
                  />
                ) : (
                  <>{team1score}</>
                )}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>

        <div className="vs"></div>

        <motion.div
          className="both2"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.2 }}
          variants={{
            visible: {
              opacity: 0.9,
              scale: 1,
              x: "-100px",
              transition: {
                type: "spring",
                stiffness: 20,
                damping: 5,
              },
            },
            hidden: { opacity: 0, scale: 1, x: 0 },
          }}
        >
          <div className="playerScore2">
            <div
              className={`score ${
                team2score > team1score
                  ? "scoreWin"
                  : team2score < team1score
                  ? "scoreLoose"
                  : "scoreDraw"
              }`}
            >
              {isEditScore ? (
                <input
                  className="score-input"
                  value={team2score}
                  onChange={(e) => setTeam2score(+e.target.value)}
                />
              ) : (
                <>{team2score}</>
              )}
            </div>
            <div className="name">{data.team2.teamname}</div>
          </div>
          <div className="player2" />
        </motion.div>
      </div>
      <div className="date">
        <>
          {isEditTime ? (
            <>
              <input
                className="dateInput"
                style={{ width: "150px" }}
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />

              <select
                value={selectedHour}
                onChange={(e) => setSelectedHour(e.target.value)}
              >
                {hours.map((list: any, i: number) => (
                  <option key={i} value={list}>
                    {list}
                  </option>
                ))}
              </select>
              <select
                value={selectedSeconds}
                onChange={(e) => setSelectedSeconds(e.target.value)}
              >
                {seconds.map((list: any, i: number) => (
                  <option key={i} value={list}>
                    {list}
                  </option>
                ))}
              </select>
              <select
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
              >
                {periods.map((list: any, i: number) => (
                  <option key={i} value={list}>
                    {list}
                  </option>
                ))}
              </select>
            </>
          ) : isEditLink ? (
            <div>
              {" "}
              <input
                value={link}
                onChange={(e) => setLink(e.target.value)}
                className="dateInput"
                placeholder=" paste game room link"
              />{" "}
            </div>
          ) : (
            <>
              {displayDate && <div className="time">{displayDate} </div>}
              <div className="sep">|</div>
              {displayTime && (
                <div className="day">{`${displayTime} (WAT)`} </div>
              )}
            </>
          )}
        </>
      </div>
      {isEditScore && (
        <div
          onClick={() => {
            if (team1score === team2score) {
              toast.error("Draw is not allowed there has to be a winner");
            } else {
              callScore({
                body: {
                  Team1: team1score,
                  Team2: team2score,
                  Winner:
                    team1score > team2score
                      ? "Team1"
                      : team2score > team1score
                      ? "Team2"
                      : "Draw",
                  TournamentId: id,
                  WinnerTeam: team1score > team2score ? data.team1 : data.team2,
                  IsFinal: isFinal ? "true" : "false",
                },
              });
            }
          }}
          className="ntn green"
        >
          Save
        </div>
      )}
      {isEditTime && (
        <div
          onClick={() => {
            callTime({
              body: {
                Time: `${selectedHour}:${selectedSeconds} ${period}`,
                Date: format(new Date(date), "yyyy-MM-dd"),
              },
            });
            setDisplayDate(format(new Date(date), "yyyy-MM-dd"));
            setDisplayTime(`${selectedHour}:${selectedSeconds} ${period}`);
          }}
          className="ntn green"
        >
          Save
        </div>
      )}
      {isEditLink && (
        <div
          onClick={() =>
            callLink({
              body: {
                Link: link,
              },
            })
          }
          className="ntn green"
        >
          Save
        </div>
      )}
    </Div>
  );
};
export default Fixture;
