import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Div } from "./style";
import Button from "../Button";

const Confirm = ({close, confirm, active, text}: any) => {
  useEffect(() => {
  }, [active]);
  return (
    <Div active={active}>
      <div className="con">
        <div>{text}</div>
        <div className="buttons">
          <div className="btn1">
            <Button theme="danger" onClick={close}>Cancel</Button>
          </div>
          <div className="btn2">
            <Button theme="green" onClick={confirm}>Ok</Button>
          </div>
        </div>
      </div>

      <div className="bg"></div>
    </Div>
  );
};

export default Confirm;
