import styled from "styled-components";

export const ModalStyle = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  .both {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text {
        padding-top: 20px;
    }
  }
  @media (max-width: 701px) {
    .modal-content-wrapper {
      width: 95vw;
    }
  }
`;
