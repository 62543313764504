import styled from "styled-components";
type Display = {
  background: string;
};
export const Div = styled.div<Display>`
  .page {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    background-color: black;
    .header {
      width: 100vw;
      height: 25vh;
      background: url("${(props) => props.background}");
      background-blend-mode: hard-light;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 20px;
      @media (max-width: 700px) {
        justify-content: left;
      }
      .but {
        margin: 10px 5px;
      }
    }
    .body {
      background: url("https://ik.imagekit.io/dmkczkqll/Gamelyd/Backgrounds/siren2.svg?updatedAt=1701866045867"),
        black 0px -0.057px / 161.712% 79.129% no-repeat;
      background-blend-mode: hard-light;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-attachment: fixed;
      @media (max-width: 600px) {
        background: url("https://ik.imagekit.io/dmkczkqll/Gamelyd/Backgrounds/siren1.svg?updatedAt=1701869410057"),
          url("https://ik.imagekit.io/dmkczkqll/Gamelyd/Backgrounds/siren.svg?updatedAt=1701865629886"),
          black 0px -0.057px / 161.712% 79.129% no-repeat;
        font-size: 12px;
        background-position: center;
        background-attachment: fixed;
      }
    }
  }
  .winner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-color: white;
    padding: 20px;
    background-color: transparent;
    border-radius: 10px;
    .bx{
      position: relative;
      .close {
        position: absolute;
        right: 0px;
        top: -25px;
        cursor: pointer;
      }
      .tx {
        position: absolute;
        top: -50px;
        font-size: 30px;
      }
    }
  }
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(
      0,
      0,
      0,
      0.7
    ); /* Adjust the alpha (last value) for transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
  }
`;
